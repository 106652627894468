<template>

  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <img src="@/assets/logo.png">
          <p>Expert comptable en ligne pour ameliorer votre vie en entreperise</p>
        </div>
        <div class="col-md-3">
          <h4>
            <b>Support</b>
          </h4>
          <ul class="list-unstyled mt-3">
            <li>
              <router-link :to="{name: 'Policy'}">Info #01</router-link>
            </li>
            <div class="mb-2"></div>
            <li>
              <a href="/CGU.pdf">Info #02</a>
            </li>
            <div class="mb-2"></div>
            <li>
              <router-link :to="{name: 'Contact'}">Info #03</router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>
            <b>Nos services</b>
          </h4>
          <ul class="list-unstyled mt-3">
            <li><a :to="{name: 'Policy'}" class="text-custom">Info #01</a></li>
            <div class="mb-2"></div>
            <li><a href="/CGU.pdf" class="text-custom">Info #02</a></li>
            <div class="mb-2"></div>
            <li><a :to="{name: 'Contact'}" class="text-custom">Info #03</a></li>
            <div class="mb-2"></div>
            <li><a :to="{name: 'Contact'}" class="text-custom">Info #04</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>
            <b>En savoir plus</b>
          </h4>
          <ul class="list-unstyled mt-3">
            <li><a :to="{name: 'Policy'}" class="text-custom">Info #01</a></li>
            <div class="mb-2"></div>
            <li><a href="/CGU.pdf" class="text-custom">Info #02</a></li>
            <div class="mb-2"></div>
            <li><a :to="{name: 'Contact'}" class="text-custom">Info #03</a></li>
            <div class="mb-2"></div>
            <li><a :to="{name: 'Contact'}" class="text-custom">Info #04</a></li>
          </ul>
        </div>
        <div class="col-md-12 copyright">
          © 2020 FreeBoss - Tous droits réservés
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  footer{
    margin-top: -30px;
    background: white;
  }
  footer>div{
    background-color: #DA4439;
    font-family: "Open sans";
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 16px;
  }
  footer>div i{
    color: #dc393a;
    margin-right: 5px;
  }
  footer>div p,.text-custom{
    color: #fafafa;
  }
  footer>div .col-md-3{
    margin-top: 20px;
    text-align: left;
  }
  footer>div .col-md-3 h4:after{
    content: "";
    display: block;
    width: 50%;
    padding-top: 10px;
    border-bottom: 2px solid white;
  }
  footer>div .col-md-3 ul li a{
    color: #DEDEDE;
    text-decoration: none;
  }
  footer>div h4,.btn-color{
    color: #fafafa;
  }
  footer>div .copyright{
    margin-top: 25px;
    color: #dfdfdf;
    text-align: right;
  }
</style>
