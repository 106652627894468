<template>
  <section>

    <Header/>

    <router-view/>
   
    <Footer/>
  </section>
</template>

<script>

import Header from "@/components/header/index.vue"

import Footer from "@/components/footer.vue"

export default {
  name: 'Home',
  components:{
    Header,
    Footer,
  },
}
</script>